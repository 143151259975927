import React from 'react';
import { Helmet } from 'react-helmet';
import { PageProps } from 'gatsby';

import Layout from '../components/Layout/Layout';
import Section from '../components/Section/Section';

const Sitemap: React.FC<PageProps> = () => (
  <Layout>
    <Helmet>
      <title>xAPI Sitemap</title>
    </Helmet>
    <Section type={'light'}>
      <p>
        <ul>
          <li>
            <strong>Draft xAPI Base Standard</strong>:{' '}
            <a href="https://xapi.ieee-saopen.org/standard">https://xapi.ieee-saopen.org/standard</a>
          </li>
          <li>
            <strong>Getting Started</strong>:{' '}
            <a href="https://xapi.ieee-saopen.org/getting-started">
              https://xapi.ieee-saopen.org/getting-started
            </a>
          </li>
          <li>
            <strong>Legal</strong>:{' '}
            <a href="https://xapi.ieee-saopen.org/legal">
              https://xapi.ieee-saopen.org/legal
            </a>
          </li>
        </ul>
      </p>
    </Section>
  </Layout>
);

export default Sitemap;

